import { useEffect, useState } from "react"

export interface ICallback {
  library: string
  milliseconds: number
  raw: string
  type: string
}

export const useQr = (
  callback: ({ library, milliseconds, raw, type }: ICallback) => void,
) => {
  const [zbarWorker, setZbarWorker] = useState<Worker>()
  const [zxingWorker, setZxingWorker] = useState<Worker>()

  useEffect(() => {
    function createWorkers() {
      try {
        const worker1 = new Worker("/zbarWorker.js")
        const worker2 = new Worker("/zxingWorker.js")

        const onmessage = (library: string) => (event_: MessageEvent) => {
          if (!event_.data) return

          const result = event_.data
          const raw = result.data
          const type = result.type
          const milliseconds = event_.data.ms
          callback({ library, milliseconds, raw, type })
        }

        worker1.onmessage = onmessage("zbar")
        worker2.onmessage = onmessage("zxing")

        setZbarWorker(worker1)
        setZxingWorker(worker2)
      } catch (error) {
        console.error(error)
      }
    }

    createWorkers()
  }, [callback])

  useEffect(() => {
    return () => {
      if (zbarWorker) zbarWorker.terminate()

      if (zxingWorker) zxingWorker.terminate()
    }
  }, [zbarWorker, zxingWorker])

  return [zbarWorker, zxingWorker]
}
