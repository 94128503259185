import { useAuthenticationStatus } from "@nhost/react"
import { Outlet, createFileRoute, useRouter } from "@tanstack/react-router"
import { Suspense, useCallback, useEffect } from "react"
import { TanStackRouterDevtools } from "#/components/TanStackRouterDevTools"

const Component = () => {
  const router = useRouter()
  const { isAuthenticated, isLoading } = useAuthenticationStatus()

  const redirectToLogin = useCallback(
    () => router.history.push("/login"),
    [router.history],
  )

  useEffect(() => {
    if (isLoading) {
      return
    }

    if (isAuthenticated) {
      return
    }

    redirectToLogin()
  }, [isLoading, isAuthenticated, redirectToLogin])

  if (isLoading) {
    return
  }

  return (
    <>
      <Outlet />
      <Suspense fallback={null}>
        <TanStackRouterDevtools />
      </Suspense>
    </>
  )
}

export const Route = createFileRoute("/_authenticated")({
  component: Component,
})
