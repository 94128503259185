import { Badge as MantineBadge } from "@mantine/core"
import ColorHash from "color-hash"
import type { FC } from "react"
import type { IBadge } from "./interfaces"

/** Badge component that displays text with a color based on the text content */
export const Badge: FC<IBadge> = ({ text }) => {
  if (!text) {
    return null
  }

  return (
    <MantineBadge autoContrast={true} color={new ColorHash().hex(text)}>
      {text}
    </MantineBadge>
  )
}
