import { createFileRoute } from "@tanstack/react-router"
import { Auth } from "../../containers/Auth"

interface IAuthSearch {
  refreshToken: string
}

export const Route = createFileRoute("/_public/auth")({
  component: () => <Auth />,
  validateSearch: (search: Record<string, string>): IAuthSearch => {
    return {
      refreshToken: search.refreshToken,
    }
  },
})
