import type { IGetMechanicPercentagesForUserQuery } from "#graphql/graphql"

export const COLORS = [
  "pink",
  "grape",
  "violet",
  "indigo",
  "blue",
  "cyan",
  "teal",
  "green",
  "lime",
  "yellow",
  "orange",
]

export const consolidateData = (data?: IGetMechanicPercentagesForUserQuery) => {
  if (!data) {
    return []
  }

  // Create a mutable copy of the data
  const mutableData = [...data.getMechanicPercentagesForUser]
  // Sort the data by count in descending order
  const sortedData = mutableData.sort((a, b) => b.count - a.count)

  // Get the top 10 entries
  const topEntries = sortedData.slice(0, 10)

  // Calculate the sum of the 'count' for all other entries
  const othersCount = sortedData
    .slice(10)
    .reduce((sum, entry) => sum + entry.count, 0)
  const othersPercentage =
    sortedData.slice(10).reduce((sum, entry) => sum + entry.percentage, 0) /
    sortedData.slice(10).length

  if (othersCount <= 0) {
    return topEntries
  }

  // Prepend the 'other' entry
  return [
    {
      count: othersCount,
      mechanic: "Other",
      percentage: othersPercentage,
    },
    ...topEntries,
  ]
}
