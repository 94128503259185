import type { Maybe } from "#graphql/graphql"

export const CleanText = (text: Maybe<string> | string | undefined) => {
  if (!text) {
    return ""
  }

  return text
    .replaceAll("&#10;", "<br/>")
    .replaceAll("&#226;&#128;&#147;", "&mdash;")
}
