import { useNhostClient } from "@nhost/react"
import { useRouter, useSearch } from "@tanstack/react-router"
import { useCallback, useEffect, useState } from "react"
import { FullPageLoader } from "#components/FullPageLoader"

export const Auth = () => {
  const router = useRouter()
  const { refreshToken } = useSearch({ from: "/_public/auth" })
  const nhost = useNhostClient()
  const [initialized, setInitialized] = useState(false)

  const login = useCallback(async () => {
    if (!refreshToken) {
      router.history.push("/")
    }

    await nhost.auth.refreshSession(refreshToken)
    router.history.push("/profile")
  }, [nhost.auth, refreshToken, router.history])

  useEffect(() => {
    if (initialized) {
      return
    }

    setInitialized(true)

    void login()
  }, [initialized, login])

  return <FullPageLoader />
}
