import { Button, Container, Text, Title } from "@mantine/core"
import { IconPlus } from "@tabler/icons-react"
import classes from "./styles.module.scss"

interface EmptyLibraryProps {
  onAddGame: () => void
  isOwner: boolean
}

export const EmptyLibrary = ({ onAddGame, isOwner }: EmptyLibraryProps) => {
  isOwner = true
  return (
    <Container className={classes.root}>
      <div className={classes.inner}>
        <div className={classes.content}>
          <Title className={classes.title}>
            {isOwner
              ? "Your board game library is empty"
              : "This library is empty"}
          </Title>
          <Text c="dimmed" size="lg">
            {isOwner
              ? "Start building your collection by adding your favorite board games. Track, organize, and share your gaming adventures!"
              : "This user hasn't added any board games to their library yet."}
          </Text>
          {isOwner && (
            <Button
              leftSection={<IconPlus size={20} />}
              size="md"
              mt="xl"
              onClick={onAddGame}
            >
              Add Your First Game
            </Button>
          )}
        </div>
      </div>
    </Container>
  )
}
