import type { FC } from "react"
import { ProductBox } from "#components/ProductBox"
import type { IGetBoardGameQuery } from "#graphql/graphql"
import "react-lazy-load-image-component/src/effects/blur.css"

import Markdown from "react-markdown"
import { Badge } from "../Badge"
import { BoardGamePlayerInfo } from "../BoardGamePlayerInfo"
import { CleanText } from "../CleanHTML"
import { ReadMore } from "../ReadMore"
import Styles from "./styles.module.scss"

const renderBadges = (
  boardGame: NonNullable<IGetBoardGameQuery["boardGame"]>,
) => {
  return (
    <div className={Styles.badges}>
      {boardGame.categories.slice(0, 5).map((category) => (
        <Badge key={category.category?.value} text={category.category?.value} />
      ))}
      {boardGame.mechanics.slice(0, 5).map((mechanic) => (
        <Badge key={mechanic.mechanic?.value} text={mechanic.mechanic?.value} />
      ))}
    </div>
  )
}

export const BoardGameDetails: FC<
  NonNullable<IGetBoardGameQuery["boardGame"]>
> = (boardGame) => {
  return (
    <div className={Styles.details}>
      <h1>{boardGame.title}</h1>
      <div className={Styles.topPart}>
        <ProductBox
          imageUrl={boardGame.imageUrl ?? boardGame.thumbnailUrl}
          thumbUrl={boardGame.thumbnailUrl}
        />
        <div className={Styles.categoryBadges}>{renderBadges(boardGame)}</div>
      </div>

      <BoardGamePlayerInfo
        minPlayers={boardGame.minPlayers}
        maxPlayers={boardGame.maxPlayers}
        recommendedPlayerCount={boardGame.recommendedPlayerCount}
        nonRecommendedPlayerCount={boardGame.nonRecommendedPlayerCount}
        playTimeInMinutes={boardGame.playTimeInMinutes}
        minAge={boardGame.minAge}
      />
      <div className={Styles.description}>
        {boardGame.summary ? (
          <Markdown
            components={{
              h1: "p",
              h2: "p",
              em(props) {
                const { node, children } = props
                return <Badge text={children as string} />
              },
            }}
          >
            {boardGame.summary}
          </Markdown>
        ) : (
          <ReadMore
            id="description"
            text={CleanText(boardGame.summary ?? boardGame.description)}
          />
        )}
      </div>
    </div>
  )
}
