import {
  Alert,
  Anchor,
  Button,
  Container,
  Paper,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core"
import { useResetPassword } from "@nhost/react"
import { useState } from "react"
import { Link } from "@tanstack/react-router"
import { IconAlertCircle, IconCheck } from "@tabler/icons-react"

export const ForgotPasswordScreen = () => {
  const [email, setEmail] = useState("")
  const [submitted, setSubmitted] = useState(false)
  const { resetPassword, isLoading, isError, error } = useResetPassword()

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const { isError } = await resetPassword(email);

    if (!isError) {
      setSubmitted(true);
    }
  }

  if (submitted) {
    return (
      <Container size="xs">
        <Paper p="xl" radius="md" withBorder>
          <Stack gap="md">
            <Alert icon={<IconCheck size={16} />} title="Check your email" color="green">
              We've sent password reset instructions to {email}
            </Alert>
            <Text size="sm" c="dimmed">
              If you don't see the email, check your spam folder. The link in the
              email will expire in 24 hours.
            </Text>
            <Anchor component={Link} to="/login" size="sm">
              Back to login
            </Anchor>
          </Stack>
        </Paper>
      </Container>
    )
  }

  return (
    <Container size="xs">
      <Paper p="xl" radius="md" withBorder>
        <form onSubmit={handleSubmit}>
          <Stack gap="md">
            <Title order={2}>Reset your password</Title>
            <Text size="sm" c="dimmed">
              Enter your email address and we'll send you instructions to reset your
              password.
            </Text>

            {isError && (
              <Alert icon={<IconAlertCircle size={16} />} color="red">
                {error?.message}
              </Alert>
            )}

            <TextInput
              label="Email address"
              placeholder="your@email.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />

            <Button type="submit" loading={isLoading} fullWidth>
              Send Reset Instructions
            </Button>

            <Anchor component={Link} to="/login" size="sm" ta="center">
              Back to login
            </Anchor>
          </Stack>
        </form>
      </Paper>
    </Container>
  )
} 