import type { FC } from "react"
import { useGetBoardGameQuery } from "#/graphql/graphql"
import "react-lazy-load-image-component/src/effects/blur.css"
import { useDebounceCallback } from "usehooks-ts"
import { BoardGameDetails as BoardGameDetailsComponent } from "#components/BoardGameDetails"
import { Skeleton } from "#components/BoardGameDetails/Skeleton"

interface IBoardGameDetails {
  thumbnailUrl: string
  uuid: string
}

export const BoardGameDetails: FC<IBoardGameDetails> = ({
  thumbnailUrl,
  uuid,
}) => {
  const { data, error, isLoading, refetch } = useGetBoardGameQuery({
    uuid,
  })
  const debouncedRefetch = useDebounceCallback(refetch, 1000)

  if (error) {
    debouncedRefetch()
  }
  console.log("Hello???")
  console.log({ data, error, isLoading, refetch })

  return isLoading || !data?.boardGame ? (
    <Skeleton thumbnailUrl={thumbnailUrl} />
  ) : (
    <BoardGameDetailsComponent {...data.boardGame} />
  )
}
