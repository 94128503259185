import { Container, Group, Stack, Text, TextInput } from "@mantine/core"
import { IconSearch } from "@tabler/icons-react"
import { useState } from "react"
import { UserCard } from "../UserCard"
import Styles from "./styles.module.scss"

interface User {
  id: string
  avatarUrl: string
  displayName: string
  boardGames_aggregate?: {
    aggregate?: {
      count?: number
    }
  }
}

interface UsersWithBoardGamesProps {
  users: User[]
}

export const UsersWithBoardGames = ({ users }: UsersWithBoardGamesProps) => {
  const [search, setSearch] = useState("")

  const filteredUsers = users.filter((user: User) =>
    user.displayName.toLowerCase().includes(search.toLowerCase()),
  )

  return (
    <Container size="lg">
      <div className={Styles.header}>
        <Group justify="space-between" align="center">
          <Stack gap="xs">
            <Text size="xl" fw={700}>
              Board Game Enthusiasts
            </Text>
            <Text c="dimmed">
              Discover fellow board game collectors and their amazing libraries
            </Text>
          </Stack>
          <TextInput
            placeholder="Search users..."
            leftSection={<IconSearch size={16} />}
            value={search}
            onChange={(e) => setSearch(e.currentTarget.value)}
            className={Styles.search}
            w="100%"
          />
        </Group>
      </div>
      <div className={Styles.userCards}>
        {filteredUsers.map((user) => (
          <div className={Styles.cardWrapper} key={user.id}>
            <UserCard
              avatarUrl={user.avatarUrl}
              displayName={user.displayName}
              gamesCount={user?.boardGames_aggregate?.aggregate?.count ?? 0}
              id={user.id}
            />
          </div>
        ))}

        {filteredUsers.length === 0 && (
          <Text ta="center" c="dimmed" py="xl">
            No users found matching your search
          </Text>
        )}
      </div>
    </Container>
  )
}
