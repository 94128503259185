import type { NhostSession } from "@nhost/react"

export interface IAuth {
  nhost: NhostSession | null
  status: "authenticated" | "unauthenticated"
}

export const auth: IAuth = {
  nhost: null,
  status: "unauthenticated",
}
