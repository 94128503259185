import { ScrollArea } from "@mantine/core"
import type { FC } from "react"
import type { ISideNav } from "./interfaces"

import Styles from "./styles.module.scss"

export const SideNav: FC<ISideNav> = ({ children }) => {
  return (
    <nav className={Styles.navbar}>
      <ScrollArea className={Styles.links}>{children}</ScrollArea>
    </nav>
  )
}
